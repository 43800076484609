import React from 'react';
import { useState, useEffect } from "react";
import { navigate } from 'gatsby';
import axios from "axios";

import Seo from "../components/Seo";
import CityResultsMap from '../components/CityResultsMap';
import IconSelect from "../components/icons/IconSelect"
import SignUpSection from '../components/SignUpSection';

export default function Results({ data }) {
  const [wardsDataReady, setWardsDataReady] = useState(null);
  const [noticesText, setNoticesText] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState(60000);

  // = = = = = = = = 
  // Hooks

  // Initial Hook: retrieves wards with final result
  const fetchElectionsResults = async () => {
    await axios.get('/.netlify/functions/getShowResultsApproval')
    .then( (response) => {
      setWardsDataReady(response.data.data);
      setNoticesText(response.data.noticeTextData.flat().reverse());
      setDataLoaded(true);
    })
    .catch( error => console.log(error) );
  }

  // useEffect( () => {
  //   if (refreshInterval && refreshInterval > 0) {
  //     const interval = setInterval(fetchElectionsResults, refreshInterval);
  //     return () => clearInterval(interval);
  //   }
  // }, [refreshInterval]);

  useEffect( () => {
    if(!dataLoaded) {
      fetchElectionsResults();
    }
  }, [dataLoaded]);


  // = = = = = = = = = = = = =
  // Data

  // Select Input Values
  const wardsArray = ["Aldersgate", "Aldgate", "Bassishaw", "Billingsgate", "Bishopsgate", "Bread_Street", "Bridge", "Broad_Street", "Candlewick", "Castle_Baynard", "Cheap", "Coleman_Street", "Cordwainer", "Cornhill", "Cripplegate", "Dowgate", "Farringdon_Within", "Farringdon_Without", "Langbourn", "Lime_Street", "Portsoken", "Queenhithe", "Tower", "Vintry", "Walbrook"];


  // = = = = = = = = = = = = =
  // Utils

  // Add id to each Heading with title as a value
  const handleChange = (e) => {
    if (e.target.value === 'Bridge') {
      navigate('/ward/bridge-and-bridge-without');  
      return;
    }

    navigate(`/ward/${e.target.value.toLowerCase().replace("_", "-")}`);
  }


  return(
    <>
      <Seo title="Voting places" />

      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <h1 className="results-map__headline"><span className="text-underline">Choose your ward</span> to see<br/>the Elections Results</h1>
            </div>
            <div className="col-md-3">
              <div className="city-map__select">
                <label htmlFor="select-ward" className="select__label">Or select from the list below</label>
                <div className="select__wrapper">

                  <select onChange={ (e) => handleChange(e) } className="select__input" name="ward" id="select-ward">
                    <option className="select__option" value="">Select</option>
                    {
                      wardsArray.map( ward => <option className="select__option" key={ ward } value={ ward }>{ward.replace("_"," ")}</option>)
                    }
                  </select>

                  <div className="select__icon">
                    <IconSelect />
                  </div>

                </div>
              </div>
            </div>

          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="results-notices">
                {
                  dataLoaded ?
                    noticesText?.map(notice => <p>• {notice}</p>)
                  :
                    <p>Loading data...</p>
                }
              </div>
            </div>
          </div>


          <div className="row">
            <div className="col-md-10 offset-md-1">

              <CityResultsMap wardsDataReady={wardsDataReady}/>

            </div>
          </div>
        </div>
      </div>

      <SignUpSection />
    </>
  )
}